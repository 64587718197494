import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Nav } from '..';
import { Heading } from 'theme-ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Heading as="h1" sx={{
      mb: 0,
      '+ p': {
        color: 'secondary',
        mt: 1,
        a: {
          color: 'inherit'
        }
      }
    }} style={{}} mdxType="Heading">
  Shrey's Notebook
    </Heading>
    <p style={{
      "marginTop": "10px",
      "lineHeight": "1.5",
      "width": "95%"
    }}> Hey there! I'm running to be your next Irvine High ASB President. On this website, you're going to find articles that relate to changes I want to make in our school for next year and why I'm qualified to do them.</p>
    <Nav mdxType="Nav" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      